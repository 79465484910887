import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // ここを追加
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () => import(/* webpackChunkName: "sign-in" */ './pages/SignIn.vue')
    },
    {
      path: '/sign-out',
      name: 'sign-out',
      // sign-out直後のbeforeEachで自動的に/sign-inに戻るため、requiresAuthは不要
      component: () => import(/* webpackChunkName: "sign-out" */ './pages/SignOut.vue')
    },
    {
      path: '/sales/register',
      name: 'sales-register',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "register-sales" */ './pages/RegisterSales.vue')
    },
    {
      path: '/sales/list',
      name: 'sales-list',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "get-sales-list" */ './pages/GetSalesList.vue')
    },
    {
      path: '/sales/shipment',
      name: 'sales-shipment',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "get-sales-shipping-list" */ './pages/GetSalesShippingList.vue')
    },
    {
      path: '/sales/remittance',
      name: 'sales-remittance',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "get-sales-remittance-application-list" */ './pages/GetSalesRemittanceApplicationList.vue')
    },
    {
      path: '/items/list',
      name: 'items-list',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "get-item-list" */ './pages/GetItemList.vue')
    },
    {
      path: '/stocks/register',
      name: 'stocks-register',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "register-stock" */ './pages/RegisterStock.vue')
    },
    {
      path: '/stocks/list',
      name: 'stocks-list',
      meta: { requiresAuth: true },
      component: () => import(/* webpackChunkName: "get-stock-list" */ './pages/GetStockList.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth) // record.meta && は不要
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        next()
      } else {
        next({
          path: '/sign-in',
          query: { redirect: to.fullPath }
        })
      }
    })
  } else {
    next()
  }
})

export default router
