<template>
    <v-app>
      <!-- navigation bar -->
      <v-navigation-drawer app v-model="drawer" >
        <v-list-item>
          <v-list-item-title class="title">
            メニュー
          </v-list-item-title>
          <v-btn icon @click="drawer=!drawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider />
        <v-list nav>
          <v-list-item v-for="menu in menus" :key="menu.title" :to="menu.url">
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- header -->
      <v-app-bar color="primary" dark app :drawer="drawer">
        <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>BSystem</v-toolbar-title>
      </v-app-bar>
      <!-- loading. must use 'start-loading' and 'end-loading' event -->
      <div id="innerLoading" v-show="innerLoading">
      </div>
      <div id="loading" v-show="loading">
        <vue-loading type="spiningDubbles" :size="{ width: '100px', height: '100px' }" />
      </div>
      <!-- main contents -->
      <v-main>
        <router-view v-on:start-loading="startLoading" v-on:end-loading="endLoading" v-on:update-menus="updateMenus"/>
      </v-main>
      <!-- footer -->
      <v-footer color="primary" dark app/>
    </v-app>
</template>

<script>
  import { VueLoading } from 'vue-loading-template'
  import InitMenus from './consts/init_menus'
  import firebase from 'firebase'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import Endpoints from './consts/api_endpoint_map.js'

  export default {
    components: {
      VueLoading
    },
    data () {
      return {
        drawer: null,
        loading: false,
        innerLoading: false,
        menus: [],
      }
    },
    computed: {
      ...mapGetters([
        'userId'
      ])
    },
    async mounted() {
      // already login, setup menus
      // const vm for `this` change
      const vm = this
      await firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          await axios.get(Endpoints.getMenuListEndpoint + `?userId=${vm.userId}`)
            .then(res => {
              if(res.data){
                vm.menus = res.data
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          vm.menus = InitMenus
        }
      })
      // スマホで v-select や 日付コンポーネントでズーム不可にさせるメタタグを付与
      document.querySelector("meta[name='viewport']").setAttribute('content', "width=device-width,initial-scale=1.0,minimum-scale=1.0")
    },
    methods: {
      async startLoading(){
        // ちらつきを防ぐため、 300ms wait 。endが300msより先に来たら黒画面にはしない
        this.innerLoading = true
        // 透明な画面で画面無効化
        const slp = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await slp(1000);
        if(this.innerLoading){
          // グレーアウト＋ローディング画像で画面無効化
          this.loading = true
        }
      },
      endLoading(){
        this.innerLoading = false
        this.loading = false
      },
      updateMenus(menus){
        console.log("caching menus")
        console.log(menus)
        this.menus = menus
      }
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.sidebar {
  /* 左側に固定 */
  float: left;
}

.footer-area {
  margin-top: 40px;
}
#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index:9999;
  position: fixed;
  background-color: rgba(#000, 0.5);
}
#innerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index:9998;
  position: fixed;
  background-color: rgba(#000, 0.0);
}
</style>
