export default Object.freeze({
	getUserEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/user/get',
	getAuthListEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/auth/get-auth-list',
	getMenuListEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/auth/get-menu-list',
	getShippersEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/user/get-shippers',
	getCacheItemStocksEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-item-stocks',
	getCacheItemStocksMapEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-item-stocks-map',
	getCacheServicesEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-services',
	getCacheServicesMapEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-services-map',
	getCacheShippingMethodsEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-shipping-methods',
	getCacheShippingMethodsMapEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-shipping-methods-map',
	getCacheItemShippingMethodsMapEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/cache/get-item-shipping-methods-map',
	getItemListEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/items/get-list',
	getSalesListEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/get-list',
	registerSalesEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/register',
	updateDealStatusEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/update-deal-status',
	updateDealStatusBatchEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/update-deal-status-batch',
	registerRemittanceApplicationEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/remittance-application',
	approveRemittanceEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/sales/remittance-approve',
	registerStockEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/stocks/register',
	getStockListEndpoint: process.env.VUE_APP_API_ENDPOINT_BASE + '/stocks/get-list',
})
