import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store.js'
import vuetify from './plugins/vuetify';

import firebase from 'firebase'

var fbConfig = {
  apiKey: "AIzaSyBcocl6r54BgV6F91l-1xBg2ac864ky5Xw",
  authDomain: "bsystem-a8d98.firebaseapp.com",
  projectId: "bsystem-a8d98",
  storageBucket: "bsystem-a8d98.appspot.com",
  messagingSenderId: "453060756446",
  appId: "1:453060756446:web:c883ee13d7a8a17221134f",
  measurementId: "G-M3VK17XSTB"
};
firebase.initializeApp(fbConfig);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
