import Vue from 'vue'
import Vuex from 'vuex'
import persisterdState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: undefined,
    userName: undefined,
    roleTypes: undefined,
  },
  getters: {
    userId (state) {
      return state.userId
    },
    userName (state) {
      return state.userName
    },
    roleTypes (state) {
      return state.roleTypes
    }
  },
  mutations: {
    setUserId (state, userId) {
      state.userId = userId
    },
    setUserName (state, userName) {
      state.userName = userName
    },
    setRoleTypes (state, roleTypes) {
      state.roleTypes = roleTypes
    },
  },
  actions: {
    setUserId (context, payload) {
      context.commit('setUserId', payload.userId)
    },
    setUserName (context, payload) {
      context.commit('setUserName', payload.userName)
    },
    setRoleTypes (context, payload) {
      context.commit('setRoleTypes', payload.roleTypes)
    },
  },
  modules: {
  },
  plugins: [persisterdState()]
})
